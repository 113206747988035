import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTitle } from "../../helper/Title";
const NotFoundComp = () => {
  useTitle("Not Found | Healthy Pregnancy");
  return (
    <>
      <div id="notfound" className="contents">
        <div className="header-svg">
          <svg
            className="notfound-cir-1"
            width="257"
            height="360"
            viewBox="0 0 257 360"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="0.5" cy="103.5" r="256.5" fill="#39C5B9" />
          </svg>
          <svg
            className="notfound-cir-2"
            width="303"
            height="287"
            viewBox="0 0 303 287"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="183" cy="104" r="183" fill="white" />
          </svg>
          <svg
            className="notfound-cir-3"
            width="513"
            height="189"
            viewBox="0 0 513 189"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="256.5" cy="256.5" r="256.5" fill="#39C5B9" />
          </svg>
        </div>

        <Row className="top-banner-login p-0 m-0">
          <Col
            // lg="12"
            sm="12"
            className="banner-left flex-column d-flex justify-content-center align-items-center"
          >
            <div className="notfound-reg-1">404</div>
            <div className="notfound-reg-2">Oops! We can’t find the page</div>
            <Link to="/" className="notfound-reg-button">
              Home
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NotFoundComp;
