import { StarRating } from "../../helper/StarRating";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../helper/dateformater";
const BlogMonthComp = ({ more, show, data }) => {
  const [showMore, SetShowMore] = useState("more");
  useEffect(() => {
    SetShowMore(show);
  }, [show]);
  if (!data) {
    <div className="blog-tab-item-nodata">No items exits!!!</div>;
  }
  return (
    <>
      {data[0]?.map((p, i) => {
        var rating = (p.like / p.views) * 5;
        return (
          <div className="blog-tab-item grid-item" key={i}>
            <div className="blog-tab-item-img">
              <Link to={`/article/${p.slug}`}>
                <img
                  srcSet={`${p.feature_image} 300w,
                    ${p.feature_image} 600w,
                    ${p.feature_image} 1000w,
                    ${p.feature_image} 2000w`}
                  sizes="(min-width: 1400px) 1400px, 92vw"
                  src={p.feature_image}
                  alt={p.title}
                  className="w-100"
                />
              </Link>
            </div>

            <Link className="blog-tab-item-title" to={`/article/${p.slug}`}>
              {p.title}
            </Link>

            <div className="blog-tab-item-desc">{p.excerpt}</div>
            <div className="d-flex justify-content-center mb-2">
              <StarRating number={p.rate_value} />
            </div>
            <div className="d-flex flex-row justify-content-around">
              <div className="blog-tab-item-footer-desc me-2">
                {p.authors[0].name}
              </div>
              <div className="blog-tab-item-footer-time">
                {formatDate(new Date(p.published_at), "/")}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-center mt-3">
              <div>
                <svg
                  width="26"
                  height="16"
                  viewBox="0 0 26 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 5.25577C15.7614 5.25577 18 7.49437 18 10.2557C18 13.0172 15.7614 15.2558 13 15.2558C10.2385 15.2558 7.99996 13.0172 7.99996 10.2557C7.99996 7.49437 10.2385 5.25577 13 5.25577ZM13 7.13075C11.2741 7.13075 9.87496 8.52987 9.87496 10.2557C9.87496 11.9816 11.2741 13.3807 13 13.3807C14.7259 13.3807 16.125 11.9816 16.125 10.2557C16.125 8.52987 14.7259 7.13075 13 7.13075ZM13 0.875C18.7669 0.875 23.7451 4.81251 25.1264 10.3305C25.2521 10.8327 24.9469 11.3419 24.4446 11.4676C23.9422 11.5933 23.4332 11.2881 23.3075 10.7858C22.1339 6.09745 17.9016 2.75 13 2.75C8.09613 2.75 3.86258 6.10033 2.69103 10.7915C2.56558 11.2937 2.05666 11.5992 1.55432 11.4739C1.05198 11.3484 0.746456 10.8395 0.871906 10.3371C2.25077 4.8159 7.23057 0.875 13 0.875Z"
                    fill="#A460A9"
                  />
                </svg>
              </div>
              <div className="mx-2 color-purple">{p.views}</div>
            </div>
            <div className="blog-tab-time">
              <svg
                width="66"
                height="66"
                viewBox="0 0 66 66"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="32.8906"
                  y="-6"
                  width="55"
                  height="55"
                  rx="15"
                  transform="rotate(45 32.8906 -6)"
                  fill="#A460A9"
                />
              </svg>
              <div className="position-absolute">
                <span>{p.reading_time}</span>
                Min
              </div>
            </div>
          </div>
        );
      })}
      {data[1] && (
        <div
          className={`blog-showmore animate__animated  ${
            showMore ? "animate__slideInDown active" : "animate__slideOutUp"
          }`}
        >
          {data[1]?.map((p, i) => {
            return (
              <div className="blog-tab-item grid-item" key={i}>
                <div className="blog-tab-item-img">
                  <Link to={`/article/${p.slug}`}>
                    <img
                      srcSet={`${p.feature_image} 300w,
                    ${p.feature_image} 600w,
                    ${p.feature_image} 1000w,
                    ${p.feature_image} 2000w`}
                      sizes="(min-width: 1400px) 1400px, 92vw"
                      src={p.feature_image}
                      alt={p.title}
                      className="w-100"
                    />
                  </Link>
                </div>

                <Link className="blog-tab-item-title" to={`/article/${p.slug}`}>
                  {p.title}
                </Link>

                <div className="blog-tab-item-desc">{p.excerpt}</div>
                <div className="d-flex justify-content-center mb-2">
                  <StarRating number={p.rate_value} />
                </div>
                <div className="d-flex flex-row justify-content-around">
                  <div className="blog-tab-item-footer-desc me-2">
                    {p.authors[0].name}
                  </div>
                  <div className="blog-tab-item-footer-time">
                    {formatDate(new Date(p.published_at), "/")}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center mt-3">
                  <div>
                    <svg
                      width="26"
                      height="16"
                      viewBox="0 0 26 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 5.25577C15.7614 5.25577 18 7.49437 18 10.2557C18 13.0172 15.7614 15.2558 13 15.2558C10.2385 15.2558 7.99996 13.0172 7.99996 10.2557C7.99996 7.49437 10.2385 5.25577 13 5.25577ZM13 7.13075C11.2741 7.13075 9.87496 8.52987 9.87496 10.2557C9.87496 11.9816 11.2741 13.3807 13 13.3807C14.7259 13.3807 16.125 11.9816 16.125 10.2557C16.125 8.52987 14.7259 7.13075 13 7.13075ZM13 0.875C18.7669 0.875 23.7451 4.81251 25.1264 10.3305C25.2521 10.8327 24.9469 11.3419 24.4446 11.4676C23.9422 11.5933 23.4332 11.2881 23.3075 10.7858C22.1339 6.09745 17.9016 2.75 13 2.75C8.09613 2.75 3.86258 6.10033 2.69103 10.7915C2.56558 11.2937 2.05666 11.5992 1.55432 11.4739C1.05198 11.3484 0.746456 10.8395 0.871906 10.3371C2.25077 4.8159 7.23057 0.875 13 0.875Z"
                        fill="#A460A9"
                      />
                    </svg>
                  </div>
                  <div className="mx-2 color-purple">{p.views}</div>
                </div>
                <div className="blog-tab-time">
                  <svg
                    width="66"
                    height="66"
                    viewBox="0 0 66 66"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="32.8906"
                      y="-6"
                      width="55"
                      height="55"
                      rx="15"
                      transform="rotate(45 32.8906 -6)"
                      fill="#A460A9"
                    />
                  </svg>
                  <div className="position-absolute">
                    <span>{p.reading_time}</span>
                    Min
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default BlogMonthComp;
