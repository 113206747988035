/**
 * retrun auth token object for sending as axios header
 */
export default function authHeader() {
  /**
   * get user json from localstorage
   */
  const user = JSON.parse(localStorage.getItem("user"));

  /**
   * Checks the logged in user
   */
  if (user && user.token) {
    return {
      Authorization: "token " + user.token,
    };
  } else {
    return {};
  }
}
