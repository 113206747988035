import React, { useEffect, useState } from "react";

export const StarRating = ({ number, clickable, callback }) => {
  const [rate, setRate] = useState();
  useEffect(() => {
    setRate(parseInt(number));
  }, [number]);
  return (
    <div
      className={`star-rating ${
        clickable ? "justify-content-start ltr mx-4" : ""
      }`}
    >
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={
              index <= parseFloat(rate)
                ? "star-button star-button-on"
                : "star-button star-button-off"
            }
            onClick={() => {
              clickable && setRate(index);
              callback && callback(index);
            }}
          >
            <span className="star">
              <svg
                width="34"
                height="33"
                viewBox="0 0 34 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9793 2.17025C15.8047 0.497947 18.1893 0.49793 19.0147 2.17025L22.9447 10.1331L31.7322 11.41C33.5777 11.6782 34.3145 13.9462 32.9792 15.2478L26.6203 21.446L28.1215 30.1982C28.4367 32.0362 26.5075 33.4378 24.8568 32.57L16.997 28.4378L9.13721 32.57C7.48656 33.4378 5.55733 32.0362 5.87258 30.1982L7.37368 21.446L1.01494 15.2478C-0.320475 13.9462 0.416425 11.6782 2.26193 11.41L11.0495 10.1331L14.9793 2.17025Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
        );
      })}
    </div>
  );
};
