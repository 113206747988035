import React from "react";
import ContentLoader from "react-content-loader";

const BlogLoader = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={618}
    // viewBox="0 0 100% 100%"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="0" width="100%" height="277" />
    <rect x="40" y="285" rx="3" ry="3" width="200" height="30" />
    <rect x="0" y="328" rx="3" ry="3" width="100%" height="20" />
    <rect x="0" y="360" rx="3" ry="3" width="100%" height="20" />
    <rect x="0" y="390" rx="3" ry="3" width="100%" height="20" />
    <rect x="0" y="420" rx="3" ry="3" width="100%" height="20" />
    <circle cx="80%" cy="480" r="23" />
    <circle cx="60%" cy="480" r="23" />
    <circle cx="40%" cy="480" r="23" />
    <circle cx="20%" cy="480" r="23" />
    <rect x="0" y="520" rx="3" ry="3" width="180" height="20" />
    <rect x="200" y="520" rx="3" ry="3" width="40" height="20" />
    <rect x="90" y="550" rx="3" ry="3" width="30" height="20" />
    <rect x="200" y="520" rx="3" ry="3" width="40" height="20" />
  </ContentLoader>
);

export default BlogLoader;
