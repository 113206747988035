import HeaderComp from "../Header";
import pngwing1 from "../../Assets/images/pngwing-1.png";
import { Col, Row } from "react-bootstrap";
import { useTitle } from "../../helper/Title";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { request_pass_reset } from "../../actions/auth";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { useState, useRef } from "react";
import ResetMessageComp from "./ResetpassMessage";
const required = (value) => {
  if (!value) {
    return (
      <div className="text-danger px-2" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="text-danger px-2" role="alert">
        This is not a valid email.
      </div>
    );
  }
};
const ResetPassRequestComp = () => {
  useTitle("Reset Password");
  const form = useRef();
  const checkBtn = useRef();
  const [email, setEmail] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const handleRequset = (e) => {
    e.preventDefault();
    setLoading((loading) => !loading);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      var data = { email: email };
      dispatch(request_pass_reset(data))
        .then(() => {
          setLoading(false);
          setSuccessful(true);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
          setSuccessful(true);
        });
    } else {
      setLoading((loading) => !loading);
    }
  };
  // console.log(message);
  return (
    <>
      <HeaderComp />
      <div id="login" className="contents">
        <div className="header-svg">
          <svg
            className="login-cir-1"
            width="184"
            height="171"
            viewBox="0 0 184 171"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="44" cy="31" r="140" fill="#39C5B9" />
          </svg>
          <svg
            className="login-cir-2"
            width="240"
            height="225"
            viewBox="0 0 240 225"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="100" cy="140" r="140" fill="#D7F171" />
          </svg>
          <svg
            className="login-cir-3"
            width="564"
            height="1100"
            viewBox="0 0 564 1100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="603.5" cy="480" rx="603.5" ry="613" fill="#39C5B9" />
          </svg>
        </div>

        <Row className="top-banner-login setpassreq p-0 m-0">
          <Col
            xl="7"
            lg="12"
            sm="12"
            className="banner-left banner-left-login d-flex justify-content-center align-items-center"
          >
            <div className="resetpass-form-container">
              {successful && typeof message != "object" ? (
                <ResetMessageComp email={email} message={message} />
              ) : (
                <Form onSubmit={handleRequset} ref={form}>
                  <div className="form-group">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <Input
                      type="text"
                      name="username"
                      id="email"
                      validations={[required, validEmail]}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      className="form-input"
                      placeholder="Please enter your Email "
                    />
                  </div>
                  <button className="login-form-button">
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    reset password
                  </button>
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
              )}
            </div>
          </Col>
          <Col xl="5" lg="12" sm="12" className="position-relative">
            <img
              src={pngwing1}
              alt="pngwing1"
              className="pngwing-login"
              style={{ borderRadius: "300px" }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ResetPassRequestComp;
