import HeaderComp from "../Header";
import { useTitle } from "../../helper/Title";
import { useState } from "react";
import Footer2Comp from "../Footer";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const FaqComp = () => {
  useTitle("FAQ | Healthy Pregnancy");
  const [openItem, SetOpenItem] = useState(3);
  const [openChild, SetOpenChild] = useState(2);
  const faq = [
    {
      q: "What is the purpose of the AI health technology service?",
      a: "Our AI health technology service is designed to provide users with AI-powered health information and analysis, based on data input by users or collected from other sources. The service is intended for informational and educational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment.",
    },
    {
      q: "How do I create an account and access the service?",
      a: "You can create an account on our website by providing your email address and creating a password. Once your account is created, you can log in and access the service.",
    },
    {
      q: "How much does the service cost?",
      a: "The service is offered on a subscription basis, and pricing will vary depending on the package you choose. You can find more information on our pricing page.",
    },
    {
      q: "Is there a free trial available?",
      a: "Yes, we offer a free trial for new users to test out the service before committing to a subscription.",
    },
    {
      q: "How accurate is the information provided by the service?",
      a: "The information provided by the service is based on the latest research and data, but it is not intended to replace professional medical advice, diagnosis, or treatment. It is important to consult with a healthcare professional for personalized and accurate information.",
    },
    {
      q: "Can I input my own data and track my progress?",
      a: "Yes, the service allows users to input their own data and track their progress over time.",
    },
    {
      q: "Is my personal information safe and secure?",
      a: (
        <div className="d-inline">
          Yes, we take the privacy and security of user data very seriously. For
          more information on how we protect your data, please refer to our
          <Link
            style={{ color: "var(--blue-2)", marginLeft: "8px" }}
            to="/policy"
          >
            privacy policy
          </Link>
          .
        </div>
      ),
    },
    {
      q: "What happens if I cancel my subscription?",
      a: "If you choose to cancel your subscription, you will lose access to the service at the end of your current billing period.",
    },
    {
      q: "How do I contact customer support if I have a problem?",
      a: "You can contact customer support by email or through our website's contact form.",
    },
    {
      q: "Are there any additional features available for an additional cost?",
      a: "Yes, some features are available as add-ons for an additional cost. You can find more information on these features on our pricing page.",
    },
  ];

  return (
    <>
      <HeaderComp />
      <div id="faq" className="contents">
        <div className="header-svg">
          <svg
            className="faq-cir-1"
            width="267"
            height="273"
            viewBox="0 0 267 273"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="-15.5" cy="-9.5" r="282.5" fill="#39C5B9" />
          </svg>
          <svg
            className="faq-cir-2"
            width="407"
            height="147"
            viewBox="0 0 407 147"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="282.5" cy="-135.5" r="282.5" fill="#39C5B9" />
          </svg>
          <svg
            className="faq-cir-3"
            width="76"
            height="565"
            viewBox="0 0 76 565"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="282.5" cy="282.5" r="282.5" fill="#D7F171" />
          </svg>
          <svg
            className="faq-cir-4"
            width="250"
            height="565"
            viewBox="0 0 429 565"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="146.5" cy="282.5" r="282.5" fill="white" />
          </svg>
          <svg
            className="faq-cir-5"
            width="358"
            height="565"
            viewBox="0 0 358 565"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="282.5" cy="282.5" r="282.5" fill="white" />
          </svg>
        </div>

        <div className="top-banner-faq">
          <Row className="faq-list-container container-md mx-md-auto w-100">
            {faq.map((f, i) => {
              return (
                <Col sm="10" className="faq-item" key={i}>
                  <div
                    className={`faq-list-items ${openItem === i ? "open" : ""}`}
                    onClick={() => {
                      SetOpenItem(i);
                    }}
                  >
                    {i + 1}.{f.q}
                  </div>
                  <div
                    className={`faq-child-container animate__animated animate__slideInDown animate__fast ${
                      openItem === i ? "open" : ""
                    }`}
                  >
                    {f.a}
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <Footer2Comp className="aboutus-footer" />
    </>
  );
};

export default FaqComp;
