import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import LandingComp from "./Components/Landing";
import LoginComp from "./Components/login";
import SignupComp from "./Components/Signup";
import CheckEmailComp from "./Components/Signup/CheckEmail";
import ResetPassRequestComp from "./Components/Signup/ResetPassRequest";
import ResetPassComp from "./Components/Signup/ResetPass";
import ResetPassFeedbackComp from "./Components/Signup/ResetPassfeedback";
import NotFoundComp from "./Components/NotFound";
import TermsComp from "./Components/Terms";
import PrivacyComp from "./Components/Privacy";
import FaqComp from "./Components/FAQ";
import BlogComp from "./Components/Blog";
import ArticleComp from "./Components/Blog/Article";
import ProfileComp from "./Components/Profile";
import { Provider } from "react-redux";
import store from "./store";
import AboutusComp from "./Components/Aboutus";
import BabyCheckComp from "./Components/BabyCheck";
import ResetPasswordComp from "./Components/Profile/ResetPass";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<LandingComp />} />
          <Route path="/community" element={<LandingComp />} />
          <Route path="/blog" element={<BlogComp />} />
          <Route path="/aboutus" element={<AboutusComp />} />
          <Route path="/article/:slug" element={<ArticleComp />} />
          <Route path="/signup" element={<SignupComp />} />
          <Route path="/login" element={<LoginComp />} />
          <Route path="/profile" element={<ProfileComp />} />
          <Route path="/checkmail" element={<CheckEmailComp />} />
          <Route path="/resetpassrequest" element={<ResetPassRequestComp />} />
          <Route path="/resetpass" element={<ResetPassComp />} />
          <Route path="/resetpassword" element={<ResetPasswordComp />} />
          <Route path="/babycheck" element={<BabyCheckComp />} />
          <Route path="/policy" element={<PrivacyComp />} />
          <Route path="/faq" element={<FaqComp />} />
          <Route path="/terms" element={<TermsComp />} />
          <Route path="*" element={<NotFoundComp />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
