import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTitle } from "../../helper/Title";
import HeaderComp from "../Header";
const TermsComp = () => {
  useTitle("Terms And Conditions | Healthy Pregnancy");
  return (
    <>
      <HeaderComp />
      <div id="Terms" className="contents">
        <div className="header-svg">
          <svg
            className="terms-cir-1"
            width="267"
            height="273"
            viewBox="0 0 267 273"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="-15.5" cy="-9.5" r="282.5" fill="#39C5B9" />
          </svg>
          <svg
            className="terms-cir-2"
            width="565"
            height="232"
            viewBox="0 0 565 232"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="282.5" cy="-50.5" r="282.5" fill="#D7F171" />
          </svg>
          <svg
            className="terms-cir-3"
            width="275"
            height="285"
            viewBox="0 0 275 285"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="-7.5" cy="282.5" r="282.5" fill="#D7F171" />
          </svg>
          <svg
            className="terms-cir-4"
            width="232"
            height="158"
            viewBox="0 0 232 158"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="282.5" cy="282.5" r="282.5" fill="white" />
          </svg>
        </div>
        <div className="container-md">
          <Row className="top-banner-login  p-0 m-0">
            <Col
              sm="12"
              className="banner-left banner-left-login flex-column d-flex justify-content-center align-items-center"
            >
              <div className="terms-container">
                <div className="terms-title terms">Terms and Conditions</div>
                <div className="terms-desc">
                  <b>Introduction:</b>
                  <br /> These terms and conditions govern your use of our AI
                  health technology service predict maternal diseases for
                  pregnant women. By accessing or using the Service, you agree
                  to be bound by these terms and conditions and our privacy
                  policy, which is incorporated by reference. If you do not
                  agree to these terms and conditions, you may not access or use
                  the Service.
                  <br />
                  <br />
                  <b>Scope of Service:</b>
                  <br /> The Service is designed to provide AI-powered health
                  information and analysis to users, based on data input by
                  users or collected from other sources. The Service is provided
                  for informational and educational purposes only, and is not
                  intended to be a substitute for professional medical advice,
                  diagnosis, or treatment.
                  <br />
                  <br />
                  <b> User Responsibilities:</b>
                  <br /> You are responsible for ensuring that any information
                  you provide to the Service is accurate and up-to-date, and
                  that you have the necessary rights to provide such
                  information. You must not use the Service for any illegal or
                  unauthorized purpose, or in any way that could damage or
                  impair the Service or any third party's use of the Service.
                  <br />
                  <br />
                  <b>Provider Responsibilities:</b> <br />
                  We will use reasonable efforts to provide the Service in
                  accordance with these terms and conditions, but we do not
                  guarantee that the Service will be available at all times or
                  that it will be error-free. We reserve the right to modify,
                  suspend, or discontinue the Service at any time, without
                  notice.
                  <br />
                  <br />
                  <b> Limitations of Liability:</b>
                  <br /> Our liability to you for any damages arising from your
                  use of the Service, whether in contract, tort, or otherwise,
                  will be limited to the greater of the amount you have paid to
                  us for use of the Service or $100.
                  <br />
                  <br />
                  <b>Data Privacy and Security:</b> <br />
                  We take the privacy and security of your data seriously.
                  Please refer to our privacy policy for more information on how
                  we collect, use, and protect your data. <br />
                  <br />
                  <b>Intellectual Property:</b> <br />
                  The Service and all content and materials included on the
                  Service, including but not limited to text, images, and
                  software, are the property of us or our licensors and are
                  protected by copyright and other intellectual property laws.
                  You may not reproduce, distribute, or otherwise use any such
                  content or materials without our prior written permission.
                  <br />
                  <br /> <b>Payment and Refunds:</b> <br />
                  If you choose to purchase a subscription or other paid
                  service, you agree to pay all fees and charges associated with
                  that service, including any applicable taxes. We will not
                  issue refunds for any fees or charges, except in the event of
                  a defective product or service.
                  <br />
                  <br /> <b>Dispute Resolution:</b>
                  <br /> Any dispute arising out of or related to these terms
                  and conditions, the Service, or any purchase made through the
                  Service will be resolved through binding arbitration in
                  accordance with the rules of the American Arbitration
                  Association. <br />
                  <br />
                  <b>Governing Law:</b>
                  <br /> These terms and conditions and your use of the Service
                  are governed by the laws of the Canada and the Province of
                  British Columbia, without giving effect to any principles of
                  conflicts of law.
                  <br />
                  <br /> <b>Entire Agreement:</b>
                  <br /> These terms and conditions, together with our privacy
                  policy, constitute the entire agreement between you and us
                  with respect to the Service, and supersede all prior or
                  contemporaneous communications and proposals, whether oral or
                  written. <br />
                  <br />
                  <b>Severability:</b>
                  <br /> If any provision of these terms and conditions is found
                  to be invalid or unenforceable, the remaining provisions will
                  remain in full force and effect.
                  <br />
                  <br /> <b>Waiver:</b> <br />
                  Our failure to enforce any right or provision in these terms
                  and conditions will not be considered a waiver of those
                  rights. If any provision of these terms and conditions is
                  found to be invalid or unenforceable, the remaining provisions
                  will remain in full force and effect.
                </div>
                <Link to="/" className="terms-button">
                  Back
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default TermsComp;
