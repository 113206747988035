import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTitle } from "../../helper/Title";
import userService from "../../services/user.service";
import HeaderComp from "../Header";
import GetDataComp from "./get_data";
import NotDataComp from "./not_complete_data";
import NotLoginComp from "./not_login";

const BabyCheckComp = () => {
  useTitle("Baby Check | Healthy Pregnancy");

  const [predict, setPredict] = useState([
    { name: "Anemia External Prediction", value: false },
    { name: "Gestational Diabetes Prediction", value: false },
    { name: "Ectopic Pragnancy Prediction", value: false },
    { name: "Hyperemesis Gravidarum prediction", value: false },
    { name: "Stillbirth Risk Prediction", value: false },
  ]);
  const [response, setResponse] = useState("no_data");
  const { isLoggedIn } = useSelector((state) => state.auth);
  useEffect(() => {
    if (isLoggedIn) {
      userService
        .getpredictions()
        .then((res) => {
          setResponse("get_data");
          var temp = [...predict];
          temp[0].value = res.anemia;
          temp[1].value = res.diabet;
          temp[2].value = res.ectopic;
          temp[3].value = res.hg;
          temp[4].value = res.stillbirth;
          setPredict(temp);
        })
        .catch((error) => {
          setResponse("no_data");
        });
    }
  }, []);

  return (
    <>
      <HeaderComp />
      <div id="aboutus" className="contents">
        <div className="header-svg">
          <svg
            className="babycheck-cir-1"
            width="565"
            height="229"
            viewBox="0 0 565 229"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="282.5" cy="-53.5" r="282.5" fill="#39C5B9" />
          </svg>
          <svg
            className="babycheck-cir-2"
            width="267"
            height="158"
            viewBox="0 0 267 158"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="-15.5" cy="-124.5" r="282.5" fill="white" />
          </svg>
          <svg
            className="babycheck-cir-3"
            width="394"
            height="227"
            viewBox="0 0 394 227"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ top: response != "no_data" ? "736px" : "686px" }}
          >
            <circle cx="167.5" cy="226.5" r="226.5" fill="#D7F171" />
          </svg>
        </div>

        <Container>
          <Row className="top-banner-aboutus top-banner-babycheck p-0 m-0">
            <Col
              sm="12"
              className="flex-column d-flex justify-content-center align-items-center"
            >
              <div className="babycheck-container">
                {isLoggedIn ? (
                  response && response == "no_data" ? (
                    <NotDataComp />
                  ) : (
                    <GetDataComp predict={predict} />
                  )
                ) : (
                  <NotLoginComp />
                )}
              </div>
            </Col>
          </Row>
        </Container>
        {/* <div className="about-us-container"></div> */}
      </div>
    </>
  );
};

export default BabyCheckComp;
