import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useRef } from "react";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import userService from "../../services/user.service";
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop + 100);
const required = (value) => {
  if (!value) {
    return (
      <div className="text-danger px-3" role="alert">
        required!
      </div>
    );
  }
};
const PragnancyComp = ({ profiledata }) => {
  const [profile, setProfile] = useState({});
  const [profileedit, setProfileEdit] = useState(false);
  const [healthyedit, setHealthyEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [bloodTest, setBloodTest] = useState([]);
  const [hi, setHi] = useState([]);
  const Profileform = useRef();
  const Profileform2 = useRef();
  const checkBtn = useRef();
  const checkBtn2 = useRef();

  const onChangeProfileField = (e, f) => {
    var name = e.target.value;
    const c = { ...profile };
    c.bmi = "?";
    if (f == "weight" || f == "height" || f == "healthInf_N_weight") {
      c[f] = parseFloat(name);
    } else {
      c[f] = name;
    }

    setProfile(c);
  };

  const EditProfile = () => {
    setProfileEdit((profileedit) => !profileedit);
  };
  const EditHealthy = () => {
    setHealthyEdit((healthyedit) => !healthyedit);
  };
  useEffect(() => {
    userService.getpredictions();
    if (typeof profiledata?.bloodT_HIV !== "undefined") {
      var b = [
        "bloodT_HIV",
        "bloodT_HVB",
        "bloodT_VZV",
        "bloodT_CMV",
        "bloodT_VDRL",
      ];
      var bt = [];
      for (const [i, v] of Object.entries(profiledata)) {
        if (b.includes(i)) {
          var name = i.split("_")[1];
          bt.push({ name, value: v });
        }
      }
      setBloodTest(bt);
    }

    if (typeof profiledata?.healthInf_Alcohol_abuse !== "undefined") {
      var b = [
        "healthInf_Alcohol_abuse",
        "healthInf_Birth_defects",
        "healthInf_Fibroids",
        "healthInf_Gestational_Diabetes",
        "healthInf_High_blood_pressure",
        "healthInf_Low_birth_weight_baby",
        "healthInf_Mental_health_disorders",
        "healthInf_Multiple_gestation",
        "healthInf_Riskconditions_mother_baby",
        "healthInf_Smoking",
        "healthInf_Stillbirth",
        "healthInf_UA",
        "healthInf_UC",
      ];
      var bt = [];
      for (const [i, v] of Object.entries(profiledata)) {
        if (b.includes(i)) {
          var name = i.replace("healthInf_", "");
          name = name.replaceAll("_", " ");
          bt.push({ name, value: v });
        }
      }
      setHi(bt);
    }

    setProfile(profiledata);
  }, [profiledata]);
  const handleEditProfile = (e) => {
    e.preventDefault();
    setLoading((loading) => !loading);
    Profileform.current.validateAll();
    if (checkBtn.current.context._errors.length === 0 && !loading) {
      // console.log(profile);
      userService.editProfileProfileInfo(profile).then(
        (response) => {
          // console.log(response);
          setLoading((loading) => !loading);
          setProfileEdit(false);
        },
        (error) => {
          setLoading((loading) => !loading);
        }
      );
    } else {
      setLoading((loading) => !loading);
    }
  };
  const handleEditHealthy = (e) => {
    e.preventDefault();
    setLoading2((loading2) => !loading2);
    // console.log(profile);
    Profileform2.current.validateAll();
    if (checkBtn2.current.context._errors.length === 0 && !loading2) {
      userService.editProfileProfileInfo(profile).then(
        (response) => {
          setLoading2((loading2) => !loading2);
          setHealthyEdit(false);
        },
        (error) => {
          setLoading2((loading2) => !loading2);
        }
      );
    } else {
      setLoading2((loading2) => !loading2);
    }
  };

  // console.log(profile);
  return (
    <div className="profile-container">
      <div className="profile-reg-1">Pregnancy</div>
      <Row style={{ paddingTop: "50px" }}>
        <Col lg="12" xl="12" xxl="6" className="profile-pragnancy-left-con">
          <Form onSubmit={handleEditProfile} ref={Profileform}>
            <div className="profile-pragnancy-left">
              <div className="profile-pragnancy-head">Blood Test</div>
              <div className="profile-pragnancy-grid">
                <div className="profile-pragnancy-item">
                  <span> BP(5-18):</span>

                  <Input
                    min={5}
                    max={18}
                    type="number"
                    className={`profile-pragnancy-item-cir ${
                      profileedit ? "" : "disabled"
                    }`}
                    name="BP"
                    value={profile?.bloodT_BP || ""}
                    disabled={profileedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "bloodT_BP")}
                    placeholder="BP"
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> HG(12-16):</span>

                  <Input
                    className={`profile-pragnancy-item-cir ${
                      profileedit ? "" : "disabled"
                    }`}
                    min={12}
                    max={16}
                    type="number"
                    name="HG"
                    value={profile?.bloodT_HG || ""}
                    disabled={profileedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "bloodT_HG")}
                    placeholder="HG"
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> RBC(1-7):</span>

                  <Input
                    className={`profile-pragnancy-item-cir ${
                      profileedit ? "" : "disabled"
                    }`}
                    type="number"
                    name="RBC"
                    min={1}
                    max={7}
                    value={profile?.bloodT_RBC || ""}
                    disabled={profileedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "bloodT_RBC")}
                    placeholder="RBC"
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> WBC(4000-15000):</span>

                  <Input
                    className={`profile-pragnancy-item-cir ${
                      profileedit ? "" : "disabled"
                    }`}
                    type="number"
                    name="WBC"
                    min={4000}
                    max={15000}
                    value={profile?.bloodT_WBC || ""}
                    disabled={profileedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "bloodT_WBC")}
                    placeholder="WBC"
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> FBS(60-120):</span>

                  <Input
                    type="number"
                    className={`profile-pragnancy-item-cir ${
                      profileedit ? "" : "disabled"
                    }`}
                    name="FBS"
                    min={60}
                    max={120}
                    value={profile?.bloodT_FBS || ""}
                    disabled={profileedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "bloodT_FBS")}
                    placeholder="FBS"
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> BUN(0-20):</span>

                  <Input
                    className={`profile-pragnancy-item-cir ${
                      profileedit ? "" : "disabled"
                    }`}
                    type="number"
                    min={0}
                    max={20}
                    name="BUN"
                    value={profile?.bloodT_BUN || ""}
                    disabled={profileedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "bloodT_BUN")}
                    placeholder="BUN"
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> UREA(0-35):</span>

                  <Input
                    type="number"
                    className={`profile-pragnancy-item-cir ${
                      profileedit ? "" : "disabled"
                    }`}
                    min={0}
                    max={35}
                    name="UREA"
                    value={profile?.bloodT_Urea || ""}
                    disabled={profileedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "bloodT_Urea")}
                    placeholder="UREA"
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> Creatinine(0.00-1.00) :</span>

                  <Input
                    type="number"
                    className={`profile-pragnancy-item-cir ${
                      profileedit ? "" : "disabled"
                    }`}
                    step="any"
                    min={0}
                    max={1}
                    name="Creatinine "
                    value={profile?.bloodT_Creatinine || ""}
                    disabled={profileedit ? false : true}
                    onChange={(e) =>
                      onChangeProfileField(e, "bloodT_Creatinine")
                    }
                    placeholder="Creatinine "
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span>TSH(0.4-4.0):</span>

                  <Input
                    min={0.4}
                    max={4.0}
                    step="any"
                    type="number"
                    className={`profile-pragnancy-item-cir ${
                      profileedit ? "" : "disabled"
                    }`}
                    name="TSH"
                    value={profile?.bloodT_TSH || ""}
                    disabled={profileedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "bloodT_TSH")}
                    placeholder="TSH"
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> T3(20-200):</span>

                  <Input
                    min={20}
                    max={200}
                    type="number"
                    className={`profile-pragnancy-item-cir ${
                      profileedit ? "" : "disabled"
                    }`}
                    name="T3"
                    value={profile?.bloodT_T3 || ""}
                    disabled={profileedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "bloodT_T3")}
                    placeholder="T3"
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> T4(0-20):</span>

                  <Input
                    min={0}
                    max={20}
                    type="number"
                    className={`profile-pragnancy-item-cir ${
                      profileedit ? "" : "disabled"
                    }`}
                    name="T4"
                    value={profile?.bloodT_T4 || ""}
                    disabled={profileedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "bloodT_T4")}
                    placeholder="T4"
                    validations={[required]}
                  />
                </div>
              </div>
              <div
                className="profile-pragnancy-grid2"
                style={{ marginBottom: "93px" }}
              >
                {bloodTest.map((b, i) => {
                  return (
                    <div className="profile-pragnancy-item" key={i}>
                      <div className="profile-switch-label">{b.name}:</div>
                      <div className="profile-switch">
                        <div
                          className="profile-switch-active"
                          style={{
                            transform: `translateX(${b.value ? "-100" : "0"}%)`,
                            transition: "all 0.3s",
                          }}
                        >
                          {b.value ? "POS" : "NEG"}
                        </div>
                        <span
                          className={`w-50 ${b.value ? "active" : ""}`}
                          onClick={() => {
                            if (profileedit) {
                              var c1 = [...bloodTest];
                              var foundIndex = bloodTest.findIndex(
                                (x) => x.name === b.name
                              );
                              c1[foundIndex].value = true;
                              var c2 = { ...profile };
                              c2["bloodT_" + b.name] = true;

                              // console.log(c1);
                              setBloodTest(c1);
                              setProfile(c2);
                            }
                          }}
                        >
                          POS
                        </span>
                        <span
                          className={`w-50 ${!b.value ? "active" : ""}`}
                          onClick={() => {
                            if (profileedit) {
                              var c1 = [...bloodTest];
                              var foundIndex = bloodTest.findIndex(
                                (x) => x.name === b.name
                              );
                              c1[foundIndex].value = false;
                              var c2 = { ...profile };
                              c2["bloodT_" + b.name] = false;

                              // console.log(c1);
                              setBloodTest(c1);
                              setProfile(c2);
                            }
                          }}
                        >
                          NEG
                        </span>
                        <input
                          type="hidden"
                          name="gender"
                          id="gender"
                          // defaultValue={gender}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {profileedit ? (
                <button
                  className={`profile-right-button ${
                    loading ? "disabled" : ""
                  }`}
                  style={{ zIndex: 1 }}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <div>Save</div>
                </button>
              ) : (
                <div
                  className="profile-right-button"
                  style={{ zIndex: 1 }}
                  onClick={() => {
                    EditProfile();
                    // executeScroll2();
                  }}
                >
                  Edit
                </div>
              )}
            </div>
            <CheckButton
              className="profile-form-button"
              style={{ display: "none" }}
              ref={checkBtn}
            />
          </Form>
        </Col>
        <Col lg="12" xl="12" xxl="6" className="profile-pragnancy-right-con">
          <Form onSubmit={handleEditHealthy} ref={Profileform2}>
            <div className="profile-pragnancy-right">
              <div className="profile-pragnancy-head">Health Information</div>
              <div className="profile-pragnancy-grid">
                <div className="profile-pragnancy-item">
                  <span> Weight:</span>
                  <Input
                    type={healthyedit ? "number" : "text"}
                    className={`profile-pragnancy-item-cir ${
                      healthyedit ? "" : "disabled"
                    }`}
                    name="Weight"
                    value={
                      profile?.weight
                        ? !healthyedit
                          ? profile?.weight + " Kg"
                          : profile.weight
                        : ""
                    }
                    disabled={healthyedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "weight")}
                    placeholder="Weight"
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> Height:</span>

                  <Input
                    type={healthyedit ? "number" : "text"}
                    className={`profile-pragnancy-item-cir ${
                      healthyedit ? "" : "disabled"
                    }`}
                    name="Height"
                    value={
                      profile?.height
                        ? !healthyedit
                          ? profile?.height + " Cm"
                          : profile.height
                        : ""
                    }
                    disabled={healthyedit ? false : true}
                    onChange={(e) => onChangeProfileField(e, "height")}
                    placeholder="Height"
                    validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> BMI:</span>
                  <Input
                    className="profile-pragnancy-item-cir disabled bmi"
                    name="BMI"
                    value={profile?.weight == "1" ? "-" : profile?.bmi || ""}
                    disabled={true}
                    placeholder="BMI"
                    // validations={[required]}
                  />
                </div>
                <div className="profile-pragnancy-item">
                  <span> abortion:</span>

                  <Input
                    type="number"
                    className={`profile-pragnancy-item-cir ${
                      healthyedit ? "" : "disabled"
                    }`}
                    name="healthInf_abortion"
                    value={profile?.healthInf_abortion || ""}
                    disabled={healthyedit ? false : true}
                    onChange={(e) =>
                      onChangeProfileField(e, "healthInf_abortion")
                    }
                    placeholder="abortion"
                    // validations={[required]}
                  />
                </div>
                {/* <div className="profile-pragnancy-item">
                  <span> N.weight:</span>

                  <Input
                    type={healthyedit ? "number" : "text"}
                    className={`profile-pragnancy-item-cir ${
                      healthyedit ? "" : "disabled"
                    }`}
                    name="healthInf_N_weight"
                    value={
                      profile?.healthInf_N_weight
                        ? !healthyedit
                          ? profile?.healthInf_N_weight + " Kg"
                          : profile.healthInf_N_weight
                        : ""
                    }
                    disabled={healthyedit ? false : true}
                    onChange={(e) =>
                      onChangeProfileField(e, "healthInf_N_weight")
                    }
                    placeholder="N.weight"
                    // validations={[required]}
                  />
                </div> */}
              </div>
              <div className="profile-pragnancy-grid2">
                {hi.map((b, i) => {
                  var name =
                    b.name == "UA" ? "U/A" : b.name == "UC" ? "U/C" : b.name;
                  return (
                    <div className="profile-pragnancy-item" key={i}>
                      <div
                        className="profile-switch-label"
                        style={{ fontSize: "16px", width: "100%" }}
                      >
                        {name}:
                      </div>
                      <div className="profile-switch">
                        <div
                          className="profile-switch-active"
                          style={{
                            transform: `translateX(${b.value ? "-100" : "0"}%)`,
                            transition: "all 0.3s",
                          }}
                        >
                          {b.value ? "POS" : "NEG"}
                        </div>
                        <span
                          className={`w-50 ${b.value ? "active" : ""}`}
                          onClick={() => {
                            if (healthyedit) {
                              var c1 = [...hi];
                              var foundIndex = hi.findIndex(
                                (x) => x.name === b.name
                              );
                              c1[foundIndex].value = true;
                              var c2 = { ...profile };

                              c2[
                                "healthInf_" + b.name.replaceAll(" ", "_")
                              ] = true;

                              // console.log(b.name);
                              setHi(c1);
                              setProfile(c2);
                            }
                          }}
                        >
                          POS
                        </span>
                        <span
                          className={`w-50 ${!b.value ? "active" : ""}`}
                          onClick={() => {
                            if (healthyedit) {
                              var c1 = [...hi];
                              var foundIndex = hi.findIndex(
                                (x) => x.name === b.name
                              );
                              c1[foundIndex].value = false;
                              var c2 = { ...profile };

                              c2[
                                "healthInf_" + b.name.replaceAll(" ", "_")
                              ] = false;

                              // console.log(c2);
                              setHi(c1);
                              setProfile(c2);
                            }
                          }}
                        >
                          NEG
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              {healthyedit ? (
                <button
                  className={`profile-right-button ${
                    loading2 ? "disabled" : ""
                  }`}
                  style={{ zIndex: 1 }}
                >
                  {loading2 && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <div>Save</div>
                </button>
              ) : (
                <div
                  className="profile-right-button"
                  style={{ zIndex: 1 }}
                  onClick={() => {
                    EditHealthy();
                    // executeScroll2();
                  }}
                >
                  Edit
                </div>
              )}
              <CheckButton
                className="profile-form-button"
                style={{ display: "none" }}
                ref={checkBtn2}
              />
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default PragnancyComp;
