import HeaderComp from "../Header";
import pngwing1 from "../../Assets/images/pngwing-1.png";
import { Col, Row } from "react-bootstrap";
import { useTitle } from "../../helper/Title";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef } from "react";
import { logout, profile_pass_reset } from "../../actions/auth";
const required = (value) => {
  if (!value) {
    return (
      <div className="text-danger px-2" role="alert">
        This field is required!
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 8 || value.length > 40) {
    return (
      <div className="text-danger px-2" role="alert">
        The password must be between 8 and 40 characters.
      </div>
    );
  }
};
const validConfirm = (value, props, components) => {
  if (value !== components["password"][0].value) {
    return (
      <div className="text-danger px-2" role="alert">
        Passwords are not equal.
      </div>
    );
  }
};
const ResetPasswordComp = () => {
  useTitle("Reset password | Healthy Pregnancy");
  const form = useRef();
  const checkBtn = useRef();
  const [password, setPassword] = useState({ password: "", confirm: "" });
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const onChangeField = (e, f) => {
    const name = e.target.value;
    const c = { ...password };
    c[f] = name;
    setPassword(c);
  };
  const handleRequsetReset = (e) => {
    e.preventDefault();
    setLoading((loading) => !loading);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      var data = {
        password: password.password,
        repeat_password: password.confirm,
      };
      // console.log(data);
      dispatch(profile_pass_reset(data))
        .then(() => {
          setLoading(false);
          setSuccessful(true);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading((loading) => !loading);
    }
  };
  // console.log(message);
  return (
    <>
      <HeaderComp />
      <div id="login" className="contents">
        <div className="header-svg">
          <svg
            className="login-cir-1"
            width="184"
            height="171"
            viewBox="0 0 184 171"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="44" cy="31" r="140" fill="#39C5B9" />
          </svg>
          <svg
            className="checkmail-cir-2"
            width="240"
            height="225"
            viewBox="0 0 240 225"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="100" cy="140" r="140" fill="#D7F171" />
          </svg>
          <svg
            className="login-cir-3"
            width="564"
            height="1100"
            viewBox="0 0 564 1100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="603.5" cy="480" rx="603.5" ry="613" fill="#39C5B9" />
          </svg>
        </div>
        <Row className="top-banner-login p-0 m-0">
          <Col
            lg="7"
            sm="12"
            className="banner-left banner-left-login d-flex justify-content-center align-items-center"
          >
            <div className="resetpass-form-container">
              {successful ? (
                message === "OK" ? (
                  <>
                    <div className="checkmail-container">
                      <div className="checkmail-desc">
                        Your password has been changed !
                      </div>
                      <Link
                        className="checkmail-button"
                        to="/login"
                        onClick={() => {
                          dispatch(logout());
                        }}
                      >
                        Back
                      </Link>
                    </div>
                  </>
                ) : (
                  // <div className="form-group px-lg-5">
                  //   {typeof message == "string" ? (
                  //     <div className="text-success">{message}</div>
                  //   ) : (
                  //     Object.values(message).map((m, i) => {
                  //       if (typeof m !== "string" && m.length > 1) {
                  //         return m.map((v, n) => {
                  //           return (
                  //             <div className="text-danger " key={n}>
                  //               {v}
                  //             </div>
                  //           );
                  //         });
                  //       } else {
                  //         return (
                  //           <div className="text-danger" key={i}>
                  //             {m}
                  //           </div>
                  //         );
                  //       }
                  //     })
                  //   )}
                  // </div>
                  ""
                )
              ) : (
                <Form onSubmit={handleRequsetReset} ref={form}>
                  <div className="form-group">
                    <label htmlFor="password" className="form-label">
                      Reset password
                    </label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      className="form-input"
                      onChange={(e) => onChangeField(e, "password")}
                      value={password.password || ""}
                      validations={[required, vpassword]}
                      placeholder="Please enter your password"
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      type="password"
                      name="confirm"
                      id="confirm"
                      className="form-input"
                      onChange={(e) => onChangeField(e, "confirm")}
                      value={password.confirm || ""}
                      validations={[required, validConfirm]}
                      placeholder="Please enter your password again"
                    />
                  </div>
                  <button
                    className="login-form-button"
                    style={{ marginTop: "4px" }}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    reset password
                  </button>
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
              )}
            </div>
          </Col>
          <Col lg="5" sm="12" className="position-relative">
            <img
              src={pngwing1}
              alt="pngwing1"
              className="pngwing-login"
              style={{ borderRadius: "300px" }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ResetPasswordComp;
