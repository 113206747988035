import axios from "axios";
import GhostContentAPI from "@tryghost/content-api";
import authHeader from "./auth-header";

var moment = require("moment");
var qs = require("querystring");

const API_URL = "http://api.healthypregnancyapp.com/";
const Ghost_url = process.env.REACT_APP_GHOST_URL;
const Ghost_POST_PATH = "/posts";
const Ghost_SLUG_PATH = "/posts/slug";
// const api = new GhostContentAPI({
//   url: process.env.REACT_APP_GHOST_URL,
//   key: process.env.REACT_APP_GHOST_CONTENT_KEY,
//   version: "v5.0",
// });
const api2 = axios.create({
  baseURL: "https://www.blog.healthypregnancyapp.com/ghost/api/content",
});

const getPosts = async (page = 1) => {
  const date = new Date();
  const last_month = moment(
    new Date(date.getFullYear(), date.getMonth() - 1, 1)
  ).format("YYYY-MM-DD HH:mm:ss");
  const last_week = moment(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)
  ).format("YYYY-MM-DD HH:mm:ss");
  // Get last 7 days posts
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);
  const filter = `published_at:>'${lastWeek.toISOString()}'`;
  var posts_month_from_ghost = await api2.get(Ghost_POST_PATH, {
    headers: {
      Accept: "application/json",
    },
    params: {
      key: "3cf928c6c7fdbb8fbf349956b7",
      include: "authors,tags",
      filter: "published_at:>'" + last_month + "'",
    },
  });
  var posts_week_from_ghost = await api2.get(Ghost_POST_PATH, {
    headers: {
      Accept: "application/json",
    },
    params: {
      key: "3cf928c6c7fdbb8fbf349956b7",
      include: "authors,tags",
      filter,
    },
  });

  var posts_from_ghost = await api2.get(Ghost_POST_PATH, {
    headers: {
      Accept: "application/json",
    },
    params: {
      key: "3cf928c6c7fdbb8fbf349956b7",
      include: "authors,tags",
      limit: 16,
      page,
    },
  });
  var post_from_back = axios.get(API_URL + "blog/");

  return await Promise.all([
    posts_from_ghost,
    post_from_back,
    posts_month_from_ghost,
    posts_week_from_ghost,
  ]).then((values) => {
    // console.log(values[3]);
    var result = [];
    var result_l_m = [];
    var result_w_m = [];
    var ids = [];
    values[1].data.posts.forEach(function (c) {
      ids.push(c.post_id);
    });
    values[0].data.posts.forEach(function (o) {
      var key = ids.findIndex((i) => i === o.id);
      result.push(Object.assign({}, values[1].data.posts[key], o));
    });
    values[2].data.posts.forEach(function (o) {
      var key = ids.findIndex((i) => i === o.id);
      result_l_m.push(Object.assign({}, values[1].data.posts[key], o));
    });
    result_l_m?.sort((a, b) => (a.views > b.views ? -1 : 1));
    result_l_m = result_l_m.slice(0, 7);
    result_l_m = result_l_m.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 4);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    values[3].data.posts.forEach(function (o) {
      var key = ids.findIndex((i) => i === o.id);
      result_w_m.push(Object.assign({}, values[1].data.posts[key], o));
    });
    result_w_m?.sort((a, b) => (a.views > b.views ? -1 : 1));
    result_w_m = result_w_m.slice(0, 7);
    result_w_m = result_w_m.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 4);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    return {
      posts: result,
      meta: values[0].data.meta,
      last_month: result_l_m,
      last_week: result_w_m,
    };
  });
};

const getPostByslug = async (slug) => {
  // var posts_from_ghost = api.posts.read(
  //   {
  //     slug,
  //   },
  //   { include: "authors,tags" }
  // );

  var posts_from_ghost = await api2.get(Ghost_SLUG_PATH + "/" + slug, {
    params: {
      key: "3cf928c6c7fdbb8fbf349956b7",
      include: "authors,tags",
    },
  });
  // console.log(posts_from_ghost);
  var post_from_back = await axios.get(API_URL + "blog/");

  return await Promise.all([posts_from_ghost, post_from_back]).then(
    (values) => {
      // console.log(values[0].data.posts[0]);
      var post_like_and_view = values[1].data.posts.filter(
        (p) => p.post_id === values[0].data.posts[0].id
      );
      return Object.assign({}, values[0].data.posts[0], post_like_and_view[0]);
    }
  );
};
const getsimilarpost = async (tag, id) => {
  // var posts_from_ghost = api.posts.browse({
  //   // include: "tags",
  //   filter: "id:-" + id + "+tag:" + tag,
  // });
  var posts_from_ghost = await api2.get(Ghost_POST_PATH, {
    params: {
      key: "3cf928c6c7fdbb8fbf349956b7",
      filter: "id:-" + id + "+tag:" + tag,
    },
  });
  var post_from_back = axios.get(API_URL + "blog/ ", {
    headers: authHeader(),
  });

  return await Promise.all([posts_from_ghost, post_from_back]).then(
    (values) => {
      var result = [];
      var ids = [];
      values[1].data.posts.forEach((c) => {
        ids.push(c.post_id);
      });
      values[0].data.posts.forEach((e) => {
        if (ids.includes(e.id)) {
          var key = ids.findIndex((i) => i === e.id);
          result.push(Object.assign({}, values[1].data.posts[key], e));
        }
      });
      return result;
    }
  );
};
const getblogsearch = async (word) => {
  return await api2
    .get(Ghost_POST_PATH, {
      params: {
        key: "3cf928c6c7fdbb8fbf349956b7",
        include: "tags",
      },
    })
    .then((res) => {
      var all = [];

      res.data.posts.filter((r) => {
        if (
          r.title?.includes(word) ||
          r.html?.includes(word) ||
          r.slug?.includes(word) ||
          r.authors?.includes(word) ||
          r.excerpt?.includes(word) ||
          r.tags?.includes(word)
        ) {
          all.push(r);
        }
      });

      return all ? all : [];
    });
};
const addpostview = (id) => {
  return axios.post(API_URL + "blog/add-post-view/", { post_id: id });
};
const likecomment = (comment_id) => {
  return axios.post(
    API_URL + "blog/comment/like/",
    { comment_id },
    {
      headers: authHeader(),
    }
  );
};
const removelikecomment = (comment_id) => {
  return axios.delete(API_URL + "blog/comment/like/", {
    headers: authHeader(),
    data: {
      comment_id,
    },
  });
};
const dislikecomment = (comment_id) => {
  return axios.post(
    API_URL + "blog/comment/dislike/",
    { comment_id },
    {
      headers: authHeader(),
    }
  );
};
const removedislikecomment = (comment_id) => {
  return axios.delete(API_URL + "blog/comment/dislike/", {
    headers: authHeader(),
    data: {
      comment_id,
    },
  });
};
const getpostcomment = async (id) => {
  var userlikecomment = await getuserlikedcomment().then((res) => {
    return res.data.liked_comments;
  });
  var userdislikecomment = await getuserdislikedcomment().then((res) => {
    return res.data.DisLiked_comments;
  });
  var data = { post_id: id };
  var getcomment = await axios
    .post(API_URL + "blog/getcomment/", qs.stringify(data), {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    });
  return Promise.all([getcomment, userlikecomment, userdislikecomment]).then(
    (values) => {
      var c = [];
      values[0].forEach((element) => {
        if (values[1].includes(element.id)) {
          element.user_like = true;
        } else {
          element.user_like = false;
        }
        if (values[2].includes(element.id)) {
          element.user_dislike = true;
        } else {
          element.user_dislike = false;
        }
        c.push(element);
      });
      return c;
    }
  );
};
const getcommentreplies = (id) => {
  return axios.get(Ghost_url + "/members/api/comments/" + id + "/replies/");
};
const getuserlikedcomment = () => {
  return axios.get(API_URL + "blog/comment/like/", {
    headers: authHeader(),
  });
};
const getuserdislikedcomment = () => {
  return axios.get(API_URL + "blog/comment/dislike/", {
    headers: authHeader(),
  });
};

const sendcomment = async (text, post_id, rate, reply_id) => {
  var data = {
    post_id,
    content: text,
    reply_id,
  };

  var x = await axios
    .post(API_URL + "blog/comment/", qs.stringify(data), {
      headers: authHeader(),
    })
    .then(async (res) => {
      if (res.status == 200) {
        await axios.post(
          API_URL + "blog/rate-post/",
          qs.stringify({ post_id, rate }),
          {
            headers: authHeader(),
          }
        );
      }

      return res;
    });
  return x;
};

export default {
  getPosts,
  getPostByslug,
  addpostview,
  getpostcomment,
  getsimilarpost,
  getcommentreplies,
  sendcomment,
  getblogsearch,
  likecomment,
  dislikecomment,
  getuserlikedcomment,
  removedislikecomment,
  removelikecomment,
};
