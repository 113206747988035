import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTitle } from "../../helper/Title";
import HeaderComp from "../Header";
const PrivacyComp = () => {
  useTitle("Privacy Policy | Healthy Pregnancy");
  return (
    <>
      <HeaderComp />
      <div id="Terms" className="contents">
        <div className="header-svg">
          <svg
            className="terms-cir-1"
            width="134"
            height="273"
            viewBox="0 0 267 273"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="-15.5" cy="-9.5" r="282.5" fill="#39C5B9" />
          </svg>
          <svg
            className="terms-cir-2"
            width="270"
            height="232"
            viewBox="0 0 565 232"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="282.5" cy="-50.5" r="282.5" fill="#D7F171" />
          </svg>
          <svg
            className="terms-cir-3"
            width="140"
            height="285"
            viewBox="0 0 275 285"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="-7.5" cy="282.5" r="282.5" fill="#D7F171" />
          </svg>
          <svg
            className="terms-cir-4"
            width="120"
            height="158"
            viewBox="0 0 232 158"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="282.5" cy="282.5" r="282.5" fill="white" />
          </svg>
        </div>

        <div className="container-md">
          <Row className="top-banner-login p-0 m-0">
            <Col
              sm="12"
              className="banner-left banner-left-login flex-column d-flex justify-content-center align-items-center"
            >
              <div className="terms-container">
                <div className="terms-title">Privacy Policy</div>
                <div className="terms-desc">
                  <h3>Introduction:</h3>
                  <p>
                    We are committed to protecting the privacy of our users.
                    This privacy policy explains how we collect, use, and
                    disclose personal information in connection with our AI
                    health technology service (the "Service"). By accessing or
                    using the Service, you agree to be bound by this privacy
                    policy.
                  </p>
                  <h3>Information Collection:</h3>
                  <p>
                    We may collect personal information from you when you use
                    the Service, such as your name, email address, and other
                    contact information, as well as information about your
                    health and medical history. We may also collect information
                    about your use of the Service, such as the features you
                    access and the data you input.
                  </p>
                  <h3>Use of Personal Information:</h3>
                  <p>
                    We may use your personal information for the following
                    purposes:
                  </p>
                  <ul>
                    <li style={{ listStyleType: "disc" }}>
                      To provide the Service to you, including processing your
                      data and providing you with personalized health
                      information and analysis;
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      To communicate with you, such as to provide you with
                      updates on the Service or to respond to your inquiries;
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      To improve the Service and develop new features;
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      To comply with legal or regulatory requirements.
                    </li>
                  </ul>
                  <h3>Sharing of Personal Information:</h3>
                  <p>
                    We will not share your personal information with any third
                    party, except as described in this privacy policy or with
                    your consent. We may share your personal information with
                    our service providers, such as data analytics companies, to
                    help us improve the Service.
                  </p>
                  <h3>Data Security:</h3>
                  <p>
                    We take reasonable measures to protect your personal
                    information from unauthorized access, use, or disclosure.
                    However, we cannot guarantee the security of your personal
                    information, and you use the Service at your own risk.
                  </p>
                  <h3>Data Retention:</h3>
                  <p>
                    We will retain your personal information for as long as
                    necessary to provide the Service, comply with legal or
                    regulatory requirements, or as otherwise described in this
                    privacy policy.
                  </p>
                  <h3>Data Access and Control:</h3>
                  <p>
                    You have the right to access and control your personal
                    information, including the right to update or delete your
                    account. Please contact us if you would like to exercise
                    these rights.
                  </p>
                  <h3>Data Transfer:</h3>
                  <p>
                    Your personal information may be transferred to, and
                    processed in, countries other than the country in which you
                    are located. These countries may have data protection laws
                    that are different from, and less protective than, the laws
                    in your country.
                  </p>
                  <h3>Changes to this Privacy Policy:</h3>
                  <p>
                    We may update this privacy policy from time to time. If we
                    make changes to this privacy policy, we will notify you by
                    revising the date at the top of this policy and, in some
                    cases, providing you with additional notice (such as adding
                    a statement to our homepage or sending you an email
                    notification). Your continued use of the Service after the
                    effective date of the revised privacy policy will be subject
                    to the new privacy policy.
                  </p>
                  <h3>Contact Information:</h3>
                  <p>
                    If you have any questions about this privacy policy or our
                    data practices, please contact us by email or through our
                    website's contact form.
                  </p>
                </div>
                <Link to="/" className="terms-button">
                  Back
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PrivacyComp;
